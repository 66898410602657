import { Alert as AlertIcon } from "app/components/Icon";
import Loader from "app/components/Loader";
import SpanLink from "app/components/SpanLink";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Icon, Message, Wrapper } from "./styles";

class AuthAlert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sending: false,
      verificationSent: false,
    };

    this.sendEmailVerification = this.sendEmailVerification.bind(this);
  }

  sendEmailVerification() {
    const { auth } = this.props;
    this.setState({ sending: true }, () =>
      auth
        .sendEmailVerification()
        .then(() => this.setState({ sending: false, verificationSent: true }))
        .catch(error => {
          this.setState({ sending: false, error });
        })
    );
  }

  render() {
    const { error, sending, verificationSent } = this.state;
    const { auth } = this.props;

    if (auth.emailVerified) {
      return null;
    }

    return (
      <Wrapper role="alert">
        <Icon as={AlertIcon} />
        <Message>
          Please verify your email by clicking the link sent to{" "}
          <strong>{auth.email}.</strong>
          {error && <strong> {error.message}</strong>}
        </Message>
        {!error && !verificationSent && !sending && (
          <SpanLink onClick={this.sendEmailVerification}>Resend email</SpanLink>
        )}
        {verificationSent && <strong ng-show="verificationSent">Sent!</strong>}
        {sending && <Loader width="20" />}
      </Wrapper>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  auth,
});

AuthAlert.propTypes = {
  auth: PropTypes.shape({
    sendEmailVerification: PropTypes.func.isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(AuthAlert);
